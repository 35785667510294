import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import { Helmet } from "react-helmet"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

class Work extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = "Work"
    const projects = data.allMdx.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Work overview" />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Livio Meyer Fotografie - Work</title>
          <link rel="canonical" href="http://livio-meyer-fotografie.ch/work" />
          <html lang="de" />
        </Helmet>
        <Bio />
        <AllProjects projects={projects} />
      </Layout>
    )
  }
}

const AllProjects = ({ projects }) => (
  <div style={{ margin: "20px 0 40px" }}>
    {projects.map(({ node }) => {
      const title = node.frontmatter.title || node.fields.slug
      console.log(node)
      return (
        <Wrapper key={node.fields.slug} style={{ marginBottom: rhythm(1 / 2) }}>
          <div style={{ 
            width: `50%`,
            paddingRight: `1em`
            }} >
            <h3
              style={{
                marginBottom: rhythm(1 / 4),
              }}
            >
              <Link style={{ boxShadow: `none` }} to={`/work${node.fields.slug}`}>
                {title}
              </Link>
            </h3>
            <p
              style={{
                overflowWrap: `break-word`,
                hyphens: `auto`
              }}
              dangerouslySetInnerHTML={{
                __html: node.frontmatter.description || node.excerpt,
              }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '50%' }}>
            <ImageContainer>
              <Link style={{ boxShadow: `none` }} to={`/work${node.fields.slug}`}>
                <Img
                  fluid={node.frontmatter.featuredImage.childImageSharp.fluid}
                  alt={node.frontmatter.title}
                  loading="eager"
                />
              </Link>
            </ImageContainer>
          </div>
        </Wrapper>
      )
    })}
  </div>
)

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const ImageContainer = styled.div`
  width: 100%;
`

export default Work

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
        filter: { fileAbsolutePath: { regex: "/work/" } }
        sort: { fields: [frontmatter___title] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            description
            featuredImage {
              childImageSharp {
                fluid (maxWidth: 2560, quality: 75){
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
